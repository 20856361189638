<template>
  <div>
    <div class="ps-2">
      <HeaderPage />
    </div>
    <SeeMorePage
      :title="'お気に入り登録商品'"
      :data_list="listProductFav"
      :data_type="'product'"
    />
    <div class="" style="margin-bottom: 8rem">
      <v-pagination
        v-if="!isLength"
        color="#ff0090"
        v-model="pageFav"
        :total-visible="5"
        :length="headerItemFavs.last_page"
        @input="nextPageFav"
      ></v-pagination>
    </div>
    <Cashier />
    <Sidebar />
  </div>
</template>
<script>
import SeeMorePage from "../components/SeeMorePage.vue";
import HeaderPage from "../components/developmentv2/HeaderPage.vue";
import Sidebar from "../components/developmentv2/Sidebar.vue";
import Cashier from "../components/Cashier.vue";

export default {
  name: "FavoritesProduct",
  components: {
    SeeMorePage,
    HeaderPage,
    Sidebar,
    Cashier,
  },
  data() {
    return {
      isLoadingHStore: true,
      pageFav: 1,
    };
  },
  beforeMount() {
    window.scrollTo(0, 0);
  },
  methods: {
    async itemFavorite() {
      this.isLoadingFav = true;
      if ($cookies.get("isLogin") == 1 && localStorage.usr_tkn) {
        let data = await this.$store.dispatch(
          "itemFavorite_module/itemFavorite",
          {
            page: this.pageFav,
          }
        );
        this.isLoadingFav = false;
        return data;
      }
    },
    nextPageFav() {
      this.$store.dispatch("itemFavorite_module/itemFavorite", {
        page: this.pageFav,
      });
    },
  },
  computed: {
    isLength() {
      return this.$store.state.itemFavorite_module.is_length;
    },
    listProductFav() {
      return this.$store.state.itemFavorite_module.itemFavoriteResult;
    },
    headerItemFavs() {
      return this.$store.state.itemFavorite_module.headerItemFavs;
    },
  },
  created() {
    this.itemFavorite();
  },
};
</script>
